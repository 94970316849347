import React, { useEffect, useState } from 'react';
import { BrowserRouter, NavLink, Redirect, Route, Switch, useParams, useHistory } from 'react-router-dom';
import LoadingIndicator from '../shared/LoadingIndicator';
import {
  FormCheckbox,
  FormDatePicker,
  FormDropDownList,
  FormInput,
  FormMultiSelect,
  FormRadioGroup,
  FormSwitch,
  FormTextArea
} from '../shared/FormComponents';
import { requiredValidator } from '../shared/FormValidators';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import withValueField from '../shared/WithValueField';
import { Field, FieldArray, Form, FormElement } from '@progress/kendo-react-form';
import { httpMethods, jobTypes, scheduleTabs } from '../shared/constants';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import JobExecutionHistory from './JobExecutionHistory';
import JobList from './JobList';
import CustomSchedulerDaily from '../custom-scheduler/customs-scheduler-daily/CustomSchedulerDaily';
import CustomSchedulerMonthly from '../custom-scheduler/custom-scheduler-monthly/CustomSchedulerMonthly';
import axios, { AxiosResponse } from 'axios';
import { JobFieldsFormGrid } from './JobFieldsFormGrid';

const DropDownListWithValueField = withValueField(DropDownList);

export default function JobDetail() {
    let { id } = useParams();
    let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [canExecute, setCanExecute] = useState(true);
  const [job, setJob] = useState({}); 
  const [visible, setVisible] = React.useState(false);
  const [scheduleErrors, setScheduleErrors] = useState({
    hours: false,
    minutes: false
  })
  const notificationPosition = {
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [jobCategories, setJobCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [notificationState, setNotificationState] = useState({
    success: false,
    error: false
  });

  const [reRender, setReRender] = useState(false);
  let dailyScheduleTypeParams = { excludes: [], weekdays: false, weekends: false };

  let monthlyScheduleTypeParams = {
    excludes: [],
    firstDayOfTheMonth: null,
    firstDayOfTheWeekOfTheMonth: '',
    lastDayOfTheMonth: null,
    lastDayOfTheWeekOfTheMonth: '',
    specificDayOfTheMonth: null,
  };
  const setDailySchedule = (value) => {
    job.dailyScheduleTypeParams = value.value;
    dailyScheduleTypeParams = value.value;
  };
  const setMonthlySchedule = (value) => {
    job.monthlyScheduleTypeParams = value.value;
    monthlyScheduleTypeParams = value.value;
  };
  const onToggleNotification = (flag) => setNotificationState({
    ...notificationState,
    [flag]: !notificationState[flag]
  });
  const onDailyScheduleUpdated = (scheduleValue) => {
    setDailySchedule(scheduleValue);
  };
  const onMonthlyScheduleUpdated = (scheduleValue) => {
    setMonthlySchedule(scheduleValue);
  };

  const toggleDialog = () => {
      setVisible(!visible);
  };

  const fetchData = (id) => {
    setIsLoading(true);
    axios.get(`api/recurring-jobs/${id}`, { method: 'GET', accept: 'application/json' })
      .then(({ data }: AxiosResponse) => {
        const params = {
          id: data.id,
          name: data.name,
          description: data.description || '',
          jobType: data.jobType,
          jobCategoryId: data.jobCategory?.id,
          tags: data.tags,
          startDate: new Date(data.startDate),
          endDate: data.endDate ? new Date(data.endDate) : null,
          fields: data.fields?.map(item => {
            let value = item.value;
            switch (item.type) {
              case 'bool':
                value = (item.value?.toLowerCase() === 'true');
                break;
              case 'number':
                value = parseInt(item.value || 0);
            }
            return {
              id: item.id,
              jobCategoryFieldId: item.jobCategoryFieldId,
              name: item.name,
              type: item.type,
              description: item.description,
              value: value
            };
          }),
          notes: data.notes || '',
          method: data.method,
          requestUrl: data.requestUrl,
          requestBody: data.requestBody || '',
          useCron: data.useCron,
          cronExpression: data.cronExpression,
          cronDescription: data.cronDescription,
          isEnabled: data.isEnabled,
          scheduleType: data.scheduleType,
          monthlyScheduleTypeParams: data.monthlyScheduleTypeParams ?? monthlyScheduleTypeParams,
          dailyScheduleTypeParams: data.dailyScheduleTypeParams ?? dailyScheduleTypeParams
        };
        setDailySchedule(params.dailyScheduleTypeParams);
        setMonthlySchedule(params.monthlyScheduleTypeParams);
        setJob(params);
        setReRender(true);
        setTimeout(() => {
          setReRender(false);
        }, 100);
        setReRender(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchJobCategories = () => {
    setIsLoading(true);
    axios.get(`api/job-categories`, { method: 'GET', accept: 'application/json' })
      .then(({ data }: AxiosResponse) => {
        setJobCategories(data);
      });
  };

  const onExecute = () => {
    setCanExecute(false);
    axios.post(`api/recurring-jobs/${id}/execute`)
      .then(({ status, data }) => {
        if (status === 200) {
          setSuccessMessage('Success! Job has been executed.');
          onToggleNotification('success');
          setIsLoading(false);
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(() => onToggleNotification('error'))
      .finally(() => setCanExecute(true));
    };

    const onDelete = () => {
        axios.delete(`api/recurring-jobs/${id}`)
            .then(({ status, data }) => {
                if (status === 200) {
                    setIsLoading(false);
                    toggleDialog();
                    history.push("/jobs");
                } else {
                    throw new Error('Something went wrong');
                }
            })
            .catch(() => onToggleNotification('error'))
            .finally();
    };

  const handleSubmit = (dataItem) => {
    setIsLoading(true);
    dataItem['scheduleTypeParams'] = job.scheduleTypeParams;

    axios.put('api/recurring-jobs', dataItem)
      .then(({ data, status, headers }: AxiosResponse) => {
        if (status === 200) {
          setSuccessMessage('Success! Job has been updated.');
          onToggleNotification('success');
          fetchData(dataItem.id);
        }
      })
      .catch((error) => {
        setErrorMessage(error?.toString());
        onToggleNotification('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkScheduleValidator = (fieldRenderProps) => {
    scheduleErrors.hours = !fieldRenderProps.hours || fieldRenderProps.hours.length === 0;
    scheduleErrors.minutes = !fieldRenderProps.minutes || fieldRenderProps.minutes.length === 0;
    if (!fieldRenderProps.hours && !fieldRenderProps.minutes) {
      return 'Sorry, you must select at least one hour and one minute for when the schedule will execute';
    }
    if (fieldRenderProps.hours.length === 0 && fieldRenderProps.minutes.length === 0) {
      return 'Sorry, you must select at least one hour and one minute for when the schedule will execute';
    }
    if (!fieldRenderProps.hours || fieldRenderProps.hours.length === 0) {
      return 'Sorry, you must select at least one hour slot for when the schedule will execute';
    }
    if (!fieldRenderProps.minutes || fieldRenderProps.minutes.length === 0) {
      return 'Sorry, you must select at least one minute slot for when the schedule will execute';
    }
  };
  const JobForm = () => {
    return (
      <React.Fragment>
        <LoadingIndicator isLoading={isLoading} />
        <Form
          onSubmit={handleSubmit}
          initialValues={job}
          key={JSON.stringify(job)}
          render={(formRenderProps) => (
            <FormElement>
                  <Toolbar>
                      <ToolbarSpacer />
                      <ToolbarItem>
                          <button
                              type={'button'}
                              className="k-button k-button-warning"
                              onClick={toggleDialog}>
                              Delete
                          </button>
                </ToolbarItem>
                <ToolbarItem>
                  <button
                    type={'button'}
                    className="k-button k-button-secondary"
                    disabled={!canExecute}
                    onClick={onExecute}>
                    Execute
                  </button>
                </ToolbarItem>
                <ToolbarItem>
                  <button
                    type={'submit'}
                    className="k-button k-button-primary"
                    id="btn-save">
                    Save Changes
                  </button>
                </ToolbarItem>
                  </Toolbar>
                  {visible && (
                      <Dialog title={"Please confirm"} onClose={toggleDialog}>
                          <p
                              style={{
                                  margin: "25px",
                                  textAlign: "center",
                              }}
                          >
                              Are you sure you want to delete this job?
                          </p>
                          <DialogActionsBar>
                              <button
                                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                  onClick={toggleDialog}
                              >
                                  No
                              </button>
                              <button
                                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                  onClick={onDelete}
                              >
                                  Yes
                              </button>
                          </DialogActionsBar>
                      </Dialog>
                  )}
              <fieldset className={'k-form-fieldset'}>
                <div className="row">
                  <div className="col">
                    <Field
                      className="float-end"
                      id={'isEnabled'}
                      name={'isEnabled'}
                      checked={formRenderProps.valueGetter('isEnabled')}
                      component={FormSwitch}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      name={'name'}
                      id="job-name"
                      component={FormInput}
                      label={'Name'}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <Field
                      name={'description'}
                      component={FormTextArea}
                      label={'Description'}
                      rows={3}
                    />
                  </div>
                </div>
                <div className="card border-secondary">
                  <div className="card-header">
                    <Field name={'jobType'} component={FormRadioGroup} data={jobTypes} layout="horizontal" />
                  </div>
                  <div className="card-body">
                    {!reRender &&
                      <div className={'col'}>
						<div className="row">
                            <div className="col-2">
                              <Field
                                id={'method'}
                                name={'method'}
                                label={'Method'}
                                data={httpMethods}
                                component={FormDropDownList}
                                validator={requiredValidator}
                              />
                            </div>
                            <div className="col-10">
                              <Field
                                name={'requestUrl'}
                                component={FormInput}
                                label={'Request Url'}
                                validator={requiredValidator}
                              />
                            </div>
						</div>
                        {formRenderProps.valueGetter('jobType') === 'HttpParameter' && <div>
                          
                          {formRenderProps.valueGetter('method') != 'GET' &&
                            <div className="row">
                              <div className="col-12">
                                <Field
                                  name={'requestBody'}
                                  component={FormTextArea}
                                  label={'Request Body'}
                                  rows={10}
                                />
                              </div>
                            </div>
                          }
                        </div>
                        }
                        {formRenderProps.valueGetter('jobType') === 'Fields' && <FieldArray
                          name={'fields'}
                          component={JobFieldsFormGrid}
                        />
                        }
                      </div>
                    }
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <Field
                      name={'useCron'}
                      component={FormCheckbox}
                      label={'Use Cron (CRON IS IN UTC TIME)'}
                      rows={10}
                    />
                    {formRenderProps.valueGetter('useCron') &&
                      <Field
                        name={'cronExpression'}
                        component={FormInput}
                        placeholder={'Cron Expression (include seconds)'}
                        rows={10}
                      />
                    }
                  </div>
                  <div className="col mt-1">
                    <span className="badge bg-secondary">
                      {job.cronDescription} (UTC TIME)
                    </span>
                  </div>
                </div>

                {!formRenderProps.valueGetter('useCron') && <>
                  <div className="card border-secondary mb-3">
                    <div className="card-header">
                      <Field name={'scheduleType'} component={FormRadioGroup} data={scheduleTabs} layout="horizontal" />
                    </div>
                    <div className="card-body">
                      {!reRender &&
                        <div className={'col'}>
                          {formRenderProps.valueGetter('scheduleType') === 'Daily' &&
                            <Field name="dailyScheduleTypeParams" component={CustomSchedulerDaily}
                                   validator={checkScheduleValidator}
                                   errors={scheduleErrors}
                                   onValueChange={onDailyScheduleUpdated} />}
                          {formRenderProps.valueGetter('scheduleType') === 'Monthly' &&
                            <Field name="monthlyScheduleTypeParams" component={CustomSchedulerMonthly}
                                   validator={checkScheduleValidator}
                                   errors={scheduleErrors}
                                   onValueChange={onMonthlyScheduleUpdated} />}
                        </div>
                      }
                    </div>
                  </div>
                </>
                }

                <div className="mb-3">
                  <Field
                    id={'jobCategoryId'}
                    name={'jobCategoryId'}
                    label={'Job Category'}
                    data={jobCategories}
                    textField="name"
                    valueField="id"
                    component={DropDownListWithValueField}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    id={'tags'}
                    name={'tags'}
                    label={'Tags'}
                    allowCustom={true}
                    component={FormMultiSelect}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <Field
                      id={'startDate'}
                      name={'startDate'}
                      label={'Start Date'}
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      id={'endDate'}
                      name={'endDate'}
                      label={'End Date'}
                      component={FormDatePicker}
                      optional={true}
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <Field
                    id={'notes'}
                    name={'notes'}
                    label={'Notes'}
                    component={FormTextArea}
                  />
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        fetchData(id);
      }, 300);
    }
  }, [id]);

  useEffect(() => {
    fetchJobCategories();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <div>
          <ul className="nav nav-pills">
            <li className="nav-item">
              <NavLink activeClassName={'active'} className={'nav-link'} to={`/jobs/${id}/details`}>Details</NavLink>
            </li>
            <li className="nav-item">
              <NavLink activeClassName={'active'} className={'nav-link'} to={`/jobs/${id}/execution-history`}>Execution
                History</NavLink>
            </li>
          </ul>
          <hr />
          <Switch>
            <Route exact path="/jobs/:id/details">
              <JobForm />
            </Route>
            <Route path="/jobs/:id/execution-history">
              <JobExecutionHistory />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
      <NotificationGroup style={notificationPosition}>
        <Fade>
          {notificationState.success && (
            <Notification
              type={{
                style: 'success',
                icon: true,
              }}
              closable={true}
              onClose={() => setNotificationState({ ...notificationState, success: false })}>
              <span>{successMessage}</span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {notificationState.error && (
            <Notification
              type={{
                style: 'error',
                icon: true,
              }}
              closable={true}
              onClose={() => setNotificationState({ ...notificationState, error: false })}>
              <span>{errorMessage || 'Something went wrong'}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>    
      </div>
  );
}
